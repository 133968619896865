import { CheckCircleOutlined } from "@ant-design/icons";
import iconApprove from "@assets/images/approve.svg";
import { Pagination } from "@components/pagination";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts/index";
import { useGetListBlogPaginateQuery, useUpdateApproveBlogMutation } from "@redux/queries/admin/admin.approve.bog";
import { Button, Image, Modal, Table, Tag } from "antd";
import DOMPurify from "dompurify";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listApproveBlog } from "./approve.enum";
import FilterBlog from "./components/FilterBlog";

const ApproveBlog = () => {
    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });
    const [filter, setFilter] = useState<any>({});

    const [modal, contextHolder] = Modal.useModal();

    const { showToast } = useToast();

    const { data, isLoading, isFetching } = useGetListBlogPaginateQuery(query as any);
    const [approveBlog] = useUpdateApproveBlogMutation();

    const columns = [
        {
            title: <div className="text-title-table">Tên người đăng</div>,
            dataIndex: "user",
            key: "user",
            render: (record: any) => {
                return <div className="">{record.firstName + " " + record.lastName}</div>;
            },
        },
        {
            title: <div className="text-title-table">Tiêu đề bài viết</div>,
            key: "title",
            width: 150,
            render: (record: any) => {
                return <a href={`https://studyinchina.io/blogs/${record.slug}`} target="_blank" rel="noreferrer">{record.title}</a>;
            },
        },
        // {
        //     title: <div className="text-title-table">Nội dung</div>,
        //     key: "content",
        //     dataIndex: "content",
        //     render: (content: any) => {
        //         return (
        //             <div
        //                 dangerouslySetInnerHTML={{
        //                     __html: DOMPurify.sanitize(content),
        //                 }}
        //                 className="content padding-top-16"
        //             />
        //         );
        //     },
        // },
        {
            title: <div className="text-title-table">Ảnh</div>,
            dataIndex: "thumbnail",
            key: "thumbnail",
            render: (thumbnail: string[]) => {
                return (
                    <Image
                        src={"" + process.env.REACT_APP_CDN + thumbnail}
                        width={100}
                    />
                );
            },
        },
        {
            title: <div className="text-title-table">Tên danh mục</div>,
            dataIndex: "category",
            key: "category",
            render: (category: any) => {
                const title = category?.language.find((item: any) => item.lang === "vi")?.title;
                return <div className="">{title}</div>;
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "approved",
            key: "approved",
            render: (approved: boolean) => {
                const statusItem = listApproveBlog.find((item) => item.value === approved?.toString());
                return <Tag color={statusItem?.color}>{statusItem?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            key: "action",
            fixed: "right" as any,
            render: (record: any) => {
                return !record?.approved ? (
                    <Button
                        className="border-0 bg-transparent"
                        onClick={() => confirmApproveBlog(record.id)}
                    >
                        <img
                            src={iconApprove}
                            alt="Approve Icon"
                        />
                    </Button>
                ) : null;
            },
        },
    ];

    const handleApprove = async (id: string) => {
        try {
            const approved = await approveBlog({ id: id });
            if ("data" in approved) {
                showToast({ ...TOAST_UPDATE_SUCCESS });
            } else {
                showToast({ ...TOAST_UPDATE_ERROR });
            }
        } catch (error) {
            showToast({ ...TOAST_UPDATE_ERROR });
        }
    };

    const confirmApproveBlog = (id: string) => {
        modal.confirm({
            title: "Thông báo",
            icon: <CheckCircleOutlined />,
            content: "Bạn có chắc chắn muốn duyệt bản ghi này?",
            okText: "Duyệt",
            cancelText: "Hủy",
            onOk() {
                handleApprove(id);
            },
            centered: true,
        });
    };

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterBlog
                                        data={filter}
                                        onSearch={(value: any) => {
                                            setFilter(value);
                                        }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                    expandable={{
                                        expandedRowRender: (e: any) => {
                                            return (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(e.content),
                                                    }}
                                                    className="content padding-top-16"
                                                />
                                            );
                                        },
                                    }}
                                />

                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {contextHolder}
            </main>
        </div>
    );
};

export default ApproveBlog;
