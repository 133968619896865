import { useGetListSchoolTypeQuery } from "@redux/queries/admin/admin.school.type";
import { useGetListProjectQuery } from "@redux/queries/admin/admin.project";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Tabs } from "antd";
import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { useGetListCityQuery } from "@redux/queries/admin/admin.city";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";
import { validateNoSpaces, validateSlug } from "@utils/common";
import _ from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "jodit";
import JoditEditor from "jodit-react";
import { editorConfig } from "@utils/editConfig";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

interface IProps {
    introduceVi: string;
    introduceEn: string;
    introduceCn: string;

    setIntroduceVi: (value: string) => void;
    setIntroduceEn: (value: string) => void;
    setIntroduceCn: (value: string) => void;

    form: any;
    editCode?: string;
}

const GeneralTab: React.FunctionComponent<IProps> = (props: IProps) => {
    const { introduceVi, introduceEn, introduceCn, setIntroduceVi, setIntroduceEn, setIntroduceCn, form, editCode } =
        props;
    const {
        data: list_school_type,
        isLoading: isLoadingSchoolType,
        error: errorSchoolType,
    } = useGetListSchoolTypeQuery();
    const { data: list_project, isLoading: isLoadingProject, error: errorProject } = useGetListProjectQuery();

    const { data: list_area, isLoading: isLoadingArea, error: errorArea } = useGetListAreaQuery();
    const { data: list_city, isLoading: isLoadingCity, error: errorCity } = useGetListCityQuery();
    const [listCitySelect, setListCitySelect] = useState<any[]>([]);

    const {
        data: list_specialized,
        isLoading: isLoadingSpecialized,
        error: errorSpecialized,
    } = useGetListSpecializedQuery();

    useEffect(() => {
        setListCitySelect(list_city || []);
    }, [list_city]);

    const handleChangeArea = (value: any) => {
        const listCity = list_city?.filter((o: any) => o.areaId === value);
        setListCitySelect(listCity || []);
    };

    const initialItems = [] as any[];

    const [activeKey, setActiveKey] = useState("vi");
    const [items, setItems] = useState(initialItems);

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const newTabIndex = useRef(0);

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <div className="row">
                    {/* col 1 */}
                    <div className="col-6">
                        <Tabs
                            type="editable-card"
                            onChange={onChange}
                            activeKey={activeKey}
                            onEdit={onEdit}
                            items={[
                                {
                                    label: "Tiếng Việt",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleVi"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "vi"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Tên trường</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="slugVi"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "vi"),
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    validateSlug(rule, value, callback, "vi");
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Slug</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    {/* <ReactQuill
                                                        className="text-editor"
                                                        value={introduceVi}
                                                        onChange={(value: any) => {
                                                            setIntroduceVi(value);
                                                        }}
                                                        formats={formats}
                                                        modules={modules}
                                                    /> */}
                                                    <JoditEditor
                                                        className="text-editor"
                                                        value={introduceVi}
                                                        config={editorConfig as any}
                                                        onBlur={(value) => setIntroduceVi(value)}
                                                    />
                                                    <label>Thông tin giới thiệu</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "vi",
                                    closable: false,
                                },
                                {
                                    label: "English",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleEn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Field required",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "en"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Name</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="slugEn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "en"),
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    validateSlug(rule, value, callback, "en");
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Slug</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    {/* <ReactQuill
                                                        className="text-editor"
                                                        value={introduceEn}
                                                        onChange={(value: any) => {
                                                            setIntroduceEn(value);
                                                        }}
                                                        formats={formats}
                                                        modules={modules}
                                                    /> */}
                                                    <JoditEditor
                                                        className="text-editor"
                                                        value={introduceEn}
                                                        config={editorConfig as any}
                                                        onBlur={(value) => setIntroduceEn(value)}
                                                    />
                                                    <label>Introduce</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "en",
                                    closable: false,
                                },
                                {
                                    label: "中国人",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleCn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "必填字段",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "cn"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>姓名</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="slugCn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "cn"),
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    validateSlug(rule, value, callback, "cn");
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Slug</label>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="form-floating">
                                                    {/* <ReactQuill
                                                        className="text-editor"
                                                        value={introduceCn}
                                                        onChange={(value: any) => {
                                                            setIntroduceCn(value);
                                                        }}
                                                        formats={formats}
                                                        modules={modules}
                                                    /> */}
                                                    <JoditEditor
                                                        className="text-editor"
                                                        value={introduceCn}
                                                        config={editorConfig as any}
                                                        onBlur={(value) => setIntroduceCn(value)}
                                                    />
                                                    <label>介绍</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "cn",
                                    closable: false,
                                },
                            ]}
                        />
                    </div>

                    {/* col 2 */}
                    <div className="col-6">
                        <Row>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="isPublic"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    allowClear
                                                >
                                                    <Select.Option value={true}>Có</Select.Option>
                                                    <Select.Option value={false}>Không</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <label>Công khai</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="code"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    {
                                                        pattern: /^[0-9\b]+$/,
                                                        message: "Phải nhập số",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    id="school-code"
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Mã trường</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="rank"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    {
                                                        pattern: /^[0-9\b]+$/,
                                                        message: "Phải nhập số",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Rank</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="areaId"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    onChange={handleChangeArea}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_area &&
                                                        list_area.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Tỉnh</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["map", "lat"]}
                                                className="form-floating"
                                            >
                                                <Input
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Lat</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="projectIds"
                                                className="form-floating"
                                            >
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    allowClear
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                >
                                                    {list_project &&
                                                        list_project.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Dự án</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="view720"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Link view 720</label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="schoolTypeId"
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    allowClear
                                                >
                                                    {list_school_type &&
                                                        list_school_type.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Loại hình trường</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="linkDetail"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Link chi tiết</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="cityId"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    // ({ getFieldValue }) => ({
                                                    //     validator(_, value) {
                                                    //         const areaId = getFieldValue("areaId");
                                                    //         const city = list_city?.find((o: any) => o.id === value);
                                                    //         if (!value || city?.areaId === areaId) {
                                                    //             return Promise.resolve();
                                                    //         }
                                                    //         return Promise.reject("Thành phố không thuộc tỉnh này");
                                                    //     },
                                                    // }),
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {listCitySelect &&
                                                        listCitySelect.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Thành phố</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["map", "lng"]}
                                                className="form-floating"
                                            >
                                                <Input
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Lng</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="specializedIds"
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Thông tin bắt buộc",
                                                //     },
                                                // ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_specialized &&
                                                        list_specialized.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Ngành học</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="syncLink"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Link đồng bộ</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.List
                                                name="view720s"
                                                initialValue={[""]}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <div
                                                                key={field.key}
                                                                className="space-medias-tab"
                                                            >
                                                                <div
                                                                    className="form-group"
                                                                    style={{
                                                                        width: "90%",
                                                                        display: "inline-block",
                                                                    }}
                                                                >
                                                                    <div className="form-floating">
                                                                        <Form.Item
                                                                            name={[field.name]}
                                                                            className="form-floating"
                                                                        >
                                                                            <Input
                                                                                type="text"
                                                                                className="no-icon form-floating"
                                                                                style={{ width: "100%" }}
                                                                            />
                                                                        </Form.Item>
                                                                        <label>Danh sách view720</label>
                                                                    </div>
                                                                </div>
                                                                {fields?.length > 0 ? (
                                                                    <MinusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        style={{
                                                                            width: "10%",
                                                                            display: "inline-block",
                                                                        }}
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{ width: "100%" }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Thêm view720
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

const MemoizedGeneralTab = React.memo(GeneralTab);

export default MemoizedGeneralTab;
